<template>
<loader v-bind="{ loading }">
  <columns>
    <column>
      <page-heading
        heading="Work Order Classification"
      />
    </column>
    <column class="is-narrow is-flex is-align-items-center">
      <router-link :to="{ name: 'work-order-classifications' }">Back</router-link>
    </column>
  </columns>
  <form>
    <columns>
      <column>
        <text-input
          classes="is-medium is-rounded"
          :value="work_order_classification.title"
          @input="updateTitle"
          :error="$root.errors.title"
          required>
          Title
        </text-input>
      </column>
      <column class="is-4">
        <switch-input
          classes="is-medium is-rounded"
          description="Will show all checklist data when drawing a PDF report."
          :value="work_order_classification.include_all_checklists"
          @input="toggleAllChecklists"
          :error="$root.errors.include_all_checklists"
          required>
          Expanded Report
        </switch-input>
      </column>
      <column class="is-narrow">
        <switch-input
          classes="is-medium is-rounded"
          description="Customer contacts will not receive notifications"
          :value="work_order_classification.internal"
          @input="toggleInternal"
          :error="$root.errors.internal"
          required>
          Internal Only
        </switch-input>
      </column>
    </columns>

    <team-segmentation />

    <report-configuration />

    <columns>
      <column>
        <submit-button
          class="is-medium is-rounded"
          :working="creating"
          @submit="createCategory">
          Save
        </submit-button>
      </column>
    </columns>
  </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ReportConfiguration from './report-configuration/ReportConfig.vue'
import TeamSegmentation from './segmentation/TeamSegmentation.vue'

export default {

  components: {
    ReportConfiguration,
    TeamSegmentation
  },

  data: () => ({
    loading: false,
    creating: false
  }),

  async beforeCreate() {
    await this.$store.dispatch('team/loadTeamList')
  },

  beforeDestroy() {
    this.clearClassification()
  },

  methods: {
    ...mapMutations('workOrderClassification', [
      'updateTitle',
      'clearClassification',
      'toggleAllChecklists',
      'toggleInternal'
    ]),
    createCategory() {
      this.creating = true
      this.$store.dispatch('workOrderClassification/create').then(() => {
        this.creating = false
        this.$toast.success('Classification successfully created')
        this.$router.push({
          name: 'work-order-classifications'
        })
      }).catch(() => this.creating = false)
    }
  },

  computed: {
    ...mapGetters('workOrderClassification', [
      'work_order_classification'
    ])
  }

}
</script>